<!-- Previous Login Page  -->

<template>

  <section class="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
    <div class="container">

      <div class="row justify-content-center form-bg-image " >
        <div class="col-5 d-flex align-items-center justify-content-center "  >
          <div class="shadow border-0 border-light p-4 w-100 loginbg" >
            <div class="text-center text-md-center mb-4 mt-4 mt-md-0 ">
              <p class="text-center mt-5">
                <img id="logo-image" :src="logoPath" style="width: 6%;" :alt="`${tenant}logo`">
              </p>
              <h1 class="mb-5 h4 link-gray-800">Talent Equity Assessment
                <br/>Client Portal
              </h1>

<!--              <h7> Don't have an account yet?</h7>-->
              <!--                                <p class="text-center" style="color: #A01850; text-decoration: underline;">Learn more about Edgility's TEA</p>-->

            </div>
            <form class="mt-4" @submit.prevent="submitForm">

              <div class="form-group">
                <!-- Form -->
                <div class="form-group mb-4 m-auto">
                  <label for="Username" >Username</label>
                  <div class="input-group">

                    <input
                        type="email"
                        class="form-control"
                        placeholder="Username"
                        id="email"
                        autofocus
                        required
                        v-model="email"
                    />
                  </div>
                </div>
                <!-- End of Form -->
                <!-- Form -->
                <div class="form-group mb-4 m-auto">
                  <label for="Password">Password</label>
                  <div class="input-group">

                    <input
                        type="password"
                        placeholder="Password"
                        class="form-control"
                        style="z-index: 0;"
                        v-model="password"
                        ref="password"
                        required
                    />
                    <svg v-if="hidePassword" @click="hidePassword=!hidePassword" style="position: absolute; top: 20%; left: 92%; opacity: 0.7; cursor: pointer; z-index: 1;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/></svg>
                    <svg v-else @click="hidePassword=!hidePassword" style="position: absolute; top: 20%; left: 92%; opacity: 0.7; cursor: pointer; z-index: 1;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16"><path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/><path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/><path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/></svg>

                  </div>
                </div>
                <!-- End of Form -->
                <div class="mb-4 m-auto ">
                  <div class="form-check " style="display: flex !important; justify-content: space-between;">
                    <div><input class="form-check-input " type="checkbox" value="" id="remember">
                      <label class="form-check-label fw-normal" for="remember">
                        Keep me logged in
                      </label>
                    </div>

                    <div>
<!--                      <router-link v-bind:to="'/forget-password/'" class="text-decoration-none text-info">-->
<!--                        Forget Password?-->
<!--                      </router-link>-->
                    </div>

                    <!--                                            <a href="./sign-in.html" class=" text-decoration-underline">Forgot Password</a>-->

                  </div>
                </div>
              </div>
              <div class="d-grid">
                <button type="submit" class="btn m-auto col-5" style="background-color: #A01850; color: white; border-radius: 0;">Log In</button>
              </div>
            </form>

            <div class="d-flex h7 justify-content-center align-items-center mt-4">
                                <span class="text-info">
                                   <a @click="forgetPassword" class="fw-bolder">Forgot Password?</a>
                                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ForgetPassword from "@/components/ForgetPassword.vue";
import axios from "axios";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      subdomain: "",
      errors: [],
      hidePassword: true
    };
  },
  watch: {
      hidePassword(oldValue, newValue){
          if (newValue){
              this.$refs.password.type='text'
          }
          else {
              this.$refs.password.type='password'
          }
      }
  },
  mounted() {
    document.title = "Login page";
  },
  computed:{
    tenant(){
      return localStorage.getItem("subdomain")
    },
    logoPath(){
      return `../assets/img/logo/${this.tenant}/logo.svg`
    }
  },
  methods: {
    async submitForm() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

      const formData = {
        email: this.email,
        password: this.password,
        subdomain: localStorage.getItem('subdomain')
      };
      await axios
        .post("api/token/", formData)
        .then((response) => {
          const token = response.data;
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.removeItem("adminToken")
          console.log("token")
          console.log(token)
          this.$store.state.isAuthenticated = true
          this.$store.commit("initializeStore");
          this.$store.state.tenantId = token.tenantId;
          this.$store.commit("setTenantFeatures")
          axios.defaults.headers.common["Authorization"] = "Token " + token.access;
          axios.defaults.headers.common['TenantID'] = token.tenantId
        }).catch((error) => {
            if(error.code === 'ERR_BAD_REQUEST'){
              this.$toast.error(`Invalid credentials`);
            }else{
              this.$toast.error(`Login failed`);
            }
          });
      const toPath = this.$route.query.to || "/dashboard";
      this.$router.push(toPath);
    },

    forgetPassword(){
      this.$router.push({name: 'forgetPassword'})
    }
  },
};
</script>
